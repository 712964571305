<template>
  <div>
    <nav-bar />
    <Hero />
    <div class="container" id="content__container">
      <div class="text-center">
        <PageHeader :title="'Welcome'"></PageHeader>
        <IntroRow />
      </div>
      <image-row-widget></image-row-widget>
      <PageHeader :title="'Events'"></PageHeader>
      <Events />
      <PageHeader :title="'Contact'"></PageHeader>
      <div class="row">
        <div class="col-12 col-md-6 col-xl-5">
          <Rehearsals></Rehearsals>
          <div class="d-block d-md-none">
            <RehearsalsMap></RehearsalsMap>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-5 offset-0 offset-xl-2">
          <Booking></Booking>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-xl-5 d-none d-md-flex">
          <RehearsalsMap></RehearsalsMap>
        </div>
        <div class="col-12 col-md-6 col-xl-5 offset-0 offset-xl-2">
          <joining-info></joining-info>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Hero from './components/structure/Hero.vue'
import IntroRow from './components/structure/Intro.vue'
import Events from './components/events/Events.vue'
import NavBar from './components/navbar/NavBar.vue'
import Rehearsals from './components/contact/Rehearsals.vue'
import Booking from './components/contact/Booking.vue'
import JoiningInfo from './components/contact/Joining.vue'
import RehearsalsMap from './components/contact/RehearsalsMap.vue'
import Footer from './components/structure/Footer.vue'
import PageHeader from './components/structure/PageHeader.vue'
import ImageRowWidget from './components/structure/ImageRowWidget.vue'

export default {
  name: 'App',
  components: {
    'nav-bar': NavBar,
    Hero,
    IntroRow,
    Events, 
    Rehearsals,
    Booking,
    JoiningInfo,
    RehearsalsMap,
    Footer,
    PageHeader,
    ImageRowWidget
  }
}
</script>

<style>
  #content__container {
    padding-bottom: 260px;
    padding-top: 0px;
  }
</style>
