<template>
    <div>
        <img src="../../assets/hrbl_location.jpg" class="rehearsal__map" />
        <p style="padding-top: 12px; text-align: right;">
            <a 
                v-bind:href="googleMapUrl" 
                target="_blank" 
                class="btn btn-primary">
                Get directions
            </a>
        </p>
    </div>
</template>

<script>
export default {
    name: 'RehearsalsMap',
    data () {
        return {
            googleMapUrl: 'https://goo.gl/maps/pAJV8fhWc99JQbGy6'
        }
    }
}
</script>

<style scoped>
.rehearsal__map {
    width: 100%;
}
</style>
