<template>
    <tr v-bind:class="{ cancelled: event.cancelled }">
        <td>
            <span class="label">
                {{date}}
            </span>
            <span class="d-inline-block d-md-none mx-2" v-if="event.timeRequired">-</span>
            <span class="d-inline-block d-md-none" v-if="event.timeRequired">
                {{time}}
            </span>
            <span class="badge badge-pill badge-danger ml-1" v-if="event.cancelled">
                Cancelled
            </span>
        </td>
        <td>
            <span class="label">
                {{event.location}}
            </span>
        </td>        
        <td class="d-none d-md-table-cell">
            <span v-if="event.timeRequired" class="label">
                {{time}}
            </span>
        </td>
    </tr>
</template>

<script>
import moment from 'moment';
export default ({
    props: ['event'],
    computed: {
        date: function () {
            return moment(this.event.date).format('MMMM Do YYYY')
        },
        time: function () {
            return moment(this.event.date).format('H:mm')
        }
    }
})
</script>

<style scoped>
    .cancelled td .label {
        text-decoration: line-through;
    }

    .cancelled td .cncl-lbl {
        text-decoration: none;
    }
</style>
