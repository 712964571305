<template>
    <div>
        <h2>Rehearsals</h2>
        <p>Whilst the band contains some employees and past employees of British Airways, nowadays the majority of members are drawn from the wider musical community and we welcome all players.</p>
        <p>The band rehearses weekly on a Wednesday evening from 8pm to 10pm at Heston Royal British Legion.</p>
        <p>We always welcome new members - just contact the <a v-bind:href="mailToAddress" target="_blank">Band Manager</a> and then come along for a blow.</p>
    </div>
</template>

<script>
import {store} from '../../store.js';

export default {
    name: 'JoiningInfo',
    data() {
        return {
            store
        };
    },
    computed: {
        mailToAddress: function () {
            return 'mailto:' + this.store.bandManagerEmail + '?subject=Enquiry%20about%20Rehearsals%20the%20British%20Airways%20Band';
        }
    }
}
</script>
