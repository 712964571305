<template>
    <div>
        <p class="h3">
            Bandroom Location and Rehearsals
        </p>
        <p>The Band reheases at: <br />
            <strong>Heston Royal British Legion</strong><br>
            <strong>79 New Heston Road</strong><br>
            <strong>Hounslow</strong><br>
            <strong>TW5 0LG</strong>
        </p>
        <p>
            The Band rehearses on Wednesday evenings from 20:00 to 22:00. New members are always welcome; please contact the Band Secretary in the first instance.
        </p>
    </div>
</template>

<script>
export default {
    name: 'RehearsalInfo'
}
</script>

