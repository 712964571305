<template>
    <div id="footer">
        <div class="container">
            <div class="row no-gutters">
                <div class="col-6 pl-2">
                    <p class="copyrightText">&copy; British Airways Brass Band</p>
                </div>
                <div class="col-6 text-right pr-2">
                    <p class="problemReport">
                        <a href="mailto:craigavisonmaher@gmail.com?subject=Issue%20On%20British%20Airways%20Brass%20Band">
                            Report a problem
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SiteFooter'
}
</script>

<style scoped>
    #footer {
        background-color: #F5F5F5;
        bottom: 0;
        font-size: 0.75em;
        height: 60px;        
        position: absolute;
        width: 100%;
    }

    #footer .container {
        overflow: auto;
        padding: 20px 0;
    }

    #footer .copyrightBlock {
        display: block;
        float: left;
    }

    #footer .webdeveloperBlock {
        display: block;
        float: right;
    }

    #footer p {
        margin: 0px 0;
    }
</style>
