<template>
    <div class="row no-gutters mt-4">
        <div class="col-6 col-md-3" v-for="(pic, index) of images" :key="index">
            <img v-bind:src="'./images/' + pic" class="img-fluid" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImageRowWidget',
    data: function (){
        return {
            images: [
                'concertband.jpg',
                'marchingband.jpg',
                'CeremonialFanfares.jpg',
                'formal_gatherings.jpg'
            ]
        }
    }
}
</script>