<template>
  <div id="babb_navbar" role="navigation">
    <div class="container">
      <div class="row py-3">
        <div class="col-9 col-md-8">
          <a href="index.html" class="h5 text-white">British Airways Brass Band</a>
        </div>
        <div class="col-3 col-md-4 text-right">
          <a
            v-bind:href="store.facebookUrl"
            target="_blank"
            class="text-white">
            Follow us
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {store} from '../../store.js'

export default {
  name: "NavBar",
  data: function () {
    return {
      store
    }
  }
};
</script>

<style scoped>
div#babb_navbar {
  background: #cd0606;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #cd0606 0%,
    #bf0404 42%,
    #7d0202 99%
  );
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, #cd0606),
    color-stop(42%, #bf0404),
    color-stop(99%, #7d0202)
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #cd0606 0%,
    #bf0404 42%,
    #7d0202 99%
  );
  background: -o-radial-gradient(
    center,
    ellipse cover,
    #cd0606 0%,
    #bf0404 42%,
    #7d0202 99%
  );
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    #cd0606 0%,
    #bf0404 42%,
    #7d0202 99%
  );
  background: radial-gradient(
    ellipse at center,
    #cd0606 0%,
    #bf0404 42%,
    #7d0202 99%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cd0606', endColorstr='#7d0202', GradientType=1);

  border-bottom: 2px solid #c19202;
  position: relative;
}

div#babb_navbar::before {
  content: "";
  border-bottom: 3px solid #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

div#babb_navbar .container {
  position: relative;
  z-index: 55;
}

div#babb_navbar .container > div {
  z-index: 65;
}
</style>
