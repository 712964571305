<template>
    <div>
        <p class="h3">Request information / Make a booking</p>
        <p>The British Airways Band can provide a range of services to meet your needs, if you are unsure of what you require or have never booked a band before don't hesitate to get in touch and we will try to help.</p>
        <p><a class="btn btn-success" v-bind:href="mailToAddress" target="_blank">Enquire about making a booking</a></p>
        <p>We can also be found on <a v-bind:href="store.facebookUrl" target="_blank">facebook</a>.</p>
    </div>
</template>

<script>
import {store} from '../../store.js'
export default {
    name: 'BookingInfo',
    data() {
        return {
            store
        };
    },
    computed: {
        mailToAddress: function () {
            return 'mailto:' + this.store.bandManagerEmail + '?subject=Enquiry%20about%20Booking%20the%20British%20Airways%20Band'
        }
    }
}
</script>
