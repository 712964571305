<template>
    <div class="row" id="intro">
        <div class="col-12">
            <p>The British Airways Band is proud to have entertained and served for over 50 years, since 1972.</p>
            <p>On this site discover our events, rehearsal details and contact information.</p>
            <p>To get our latest news and information follow us on <a v-bind:href="store.facebookUrl" target="_blank">facebook</a>.</p>
        </div>
    </div>
</template>

<script>
import {store} from '../../store.js';

export default {
    name: 'IntroRow',
    data: function() {
        return {
            store
        }
    }
}
</script>
