<template>
    <div class="jumbotron mb-0" id="hero_container">
        <div class="col-6 offset-3 align-middle">
            <!-- <p class="display-3 text-white text-center text-justify jumbotron-text py-4 my-4">
                {{ heroText }}
            </p> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HomeHero',
        data() {
            return {
                heroText: 'Proud to have served and entertained for 40 years, since 1972'
            }
        }
    }
</script>

<style scoped>
    #hero_container {
        background-image: url("../../assets/babb_at_windsor_2.jpg");
        background-repeat: no-repeat;
        background-position: center center;
        background-color: transparent;
        background-size: 100% auto;
        margin-top: -57px;
        min-height: 500px;
        padding-bottom: 48px;
        padding-top: 48px;    
    }

    .jumbotron-text {
        text-shadow: 2px 2px 2px #222;
    }

    @media all and (min-width: 1px) and (max-width: 860px) {
        #hero_container {
            background-size: cover;
            min-height: 400px;
        }
    }
</style>
