<template>
    <div class="page-header">
        <p class="h1">{{ title }}</p>
    </div>
</template>

<script>
export default {
    name: 'PageHeader',
    props: ['title']
}
</script>

<style scoped>
    .page-header {
        padding-bottom: 9px;
        margin: 40px 0 20px;
        border-bottom: 1px solid #eee;
    }
</style>
