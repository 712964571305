<template>
  <div class="row" id="events">
      <div class="col-12">
        <p>
            Our current booked events are listed below. Please check back regularly as the list is frequently updated.
        </p>
        <div class="table-responsive bg-white">
          <table class="table">
            <thead class="thead-dark">
              <tr class="d-none d-md-table-row">
                <th>Date</th>
                <th>Event</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              <EventRow
                v-for="(event, index) of filteredEvents"
                :key="index"
                v-bind:event="event"
              >
              </EventRow>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</template>

<script>
import EventRow from "./EventRow.vue";
import moment from 'moment';

export default {
  name: "events-list",
  data() {
    return {
      events: [],
    };
  },
  async created () {
    this.events = await this.getEvents();
  },
  computed: {
    filteredEvents () {
      // Get future events and put them in ascending order by date.
      const futureEvents = this.events.filter(function (item) {        
        return moment().isBefore(item.date);
      }).sort(function (a, b) {
          let key1 = a.date;
          let key2 = b.date;

          if (key1 < key2) {
              return -1;
          } else if (key1 == key2) {
              return 0;
          } else {
              return 1;
          }
      });

      return futureEvents;
    }
  },
  methods: {
    getEvents: async () => {
      const query = `
{
  eventCollection {
    items {
      sys {
        id
      }
      date
      location
      cancelled
      timeRequired
    }
  }
}
      `;
      const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${process.env.VUE_APP_CONTENTFUL_SPACE_ID}`;
       const fetchOptions = {
         method: "POST",
         headers: {
           Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
            "Content-Type": "application/json"
          },
           body: JSON.stringify({
            query
          })
        };

      try {
        const response = await fetch(fetchUrl, fetchOptions).then(response =>
          response.json()
        );
        return response.data.eventCollection.items;
      } catch (error) {
        throw new Error("Could not receive the data from Contentful!");
      }    
    }
  },
  components: {
    EventRow: EventRow,
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
}
</style>
